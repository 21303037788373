import { useState, useEffect } from "react";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mobile View
  if (isMobile) {
    return (
      <footer className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-2 mt-auto">
        <div className="container mx-auto text-center">
          <p className="text-sm text-slate-50">
            Copyright © 2024{/*  Wellztech */}. All rights reserved.
          </p>
        </div>
      </footer>
    );
  }

  // PC View
  return (
    <footer className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-6 mt-auto">
      <div className="container mx-auto text-center">
        <p className="text-lg text-slate-50">
          Copyright © 2024{/*  Wellztech */}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
