import { useState, useEffect } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { ChromePicker } from "react-color";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ModelsSidebar = ({
  modelsRefs,
  onOpacityChange,
  onVisibilityChange,
  onColorChange,
  onColorSave,
}) => {
  const [visibility, setVisibility] = useState([]);
  const [opacity, setOpacity] = useState([]);
  const [colors, setColors] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState([]);

  useEffect(() => {
    setVisibility(modelsRefs.map((model) => model.visible));
    setOpacity(modelsRefs.map((model) => model.material.opacity));
    setColors(modelsRefs.map((model) => model.material.color.getStyle()));
    setShowColorPicker(modelsRefs.map(() => false));
  }, [modelsRefs]);

  const handleVisibilityChange = (index) => {
    const model = modelsRefs[index];
    if (model) {
      model.visible = !model.visible;
      const newVisibility = [...visibility];
      newVisibility[index] = model.visible;
      onVisibilityChange(index, model.visible);
      setVisibility(newVisibility);
    }
  };

  const handleOpacityChange = (index, value) => {
    const model = modelsRefs[index];
    if (model) {
      const newOpacity = value;
      const opacityChange = [...opacity];
      opacityChange[index] = newOpacity;
      onOpacityChange(index, newOpacity);
      setOpacity(opacityChange);
    }
  };

  const handleColorChange = (color, index) => {
    const newColors = [...colors];
    newColors[index] = color.hex;
    setColors(newColors);
    onColorChange(index, color.hex);
  };

  const toggleColorPicker = (index) => {
    const newShowColorPicker = showColorPicker.map((show, i) =>
      i === index ? !show : false
    );
    setShowColorPicker(newShowColorPicker);
  };

  const handleSaveColor = (index) => {
    const selectedColor = colors[index];
    onColorSave(index, selectedColor);

    const newShowColorPicker = [...showColorPicker];
    newShowColorPicker[index] = false;
    setShowColorPicker(newShowColorPicker);
  };

  return (
    <div className="absolute right-0 top-12 w-64 md:w-44 bg-gray-100 overflow-y-auto shadow-lg z-20 h-[87vh]">
      <ul className="list-none m-0 p-0 ">
        {modelsRefs.map((modelRef, index) => (
          <li
            key={index}
            className="cursor-pointer p-4 hover:bg-blue-100 transition-colors duration-150 ease-in-out flex flex-col space-y-2 border-b border-gray-200"
          >
            <div className="flex justify-between space-x-4 md:space-x-0 w-full">
              <div className="flex items-center space-x-2 md:space-x-1">
                <div className="relative">
                  <button
                    style={{ backgroundColor: colors[index] }}
                    className="w-6 h-6 md:w-4 md:h-4 rounded-full border-2 border-gray-300"
                    onClick={() => toggleColorPicker(index)}
                  ></button>
                  {showColorPicker[index] && (
                    <div className="absolute z-10 shadow-md rounded bg-gray-100 w-56 md:w-36 flex flex-col items-center">
                      <ChromePicker
                        color={colors[index]}
                        onChange={(color) => handleColorChange(color, index)}
                        disableAlpha={true}
                        styles={{
                          default: {
                            picker: {
                              width: "100%",
                              boxSizing: "border-box",
                            },
                          },
                        }}
                      />
                      <button
                        className="my-2 px-4 py-1 text-white text-center bg-blue-500 rounded-md hover:bg-blue-600"
                        onClick={() => handleSaveColor(index)}
                      >
                        Kaydet
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className={`text-gray-700 font-medium md:font-normal ${
                    modelRef.name.length > 20
                      ? "text-xs"
                      : modelRef.name.length > 10
                      ? "text-sm md:text-xs"
                      : "text-base md:text-sm"
                  }`}
                >
                  {modelRef.name}
                </div>
              </div>
              <div className="flex items-center space-x-2 md:space-x-1">
                {visibility[index] ? (
                  <VisibilityOutlinedIcon
                    className="text-gray-600 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="text-gray-300 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2 md:space-x-0">
              <span className="mr-2 md:text-xs">Opacity:</span>
              <Slider
                min={0}
                max={1}
                step={0.1}
                value={opacity[index]}
                onChange={(value) => handleOpacityChange(index, value)}
                className="w-auto"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModelsSidebar;
